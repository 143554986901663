<template lang="pug">
div
  van-sticky
    van-search(v-model='searchKey', placeholder='请输入搜索关键词' @search="onSearch" show-action shape="round")
      template(#action='')
        div(@click='onSearch') 搜索
    van-dropdown-menu
      van-dropdown-item(v-model='value', :options='option' ref="sort")
      van-dropdown-item.has-filter(ref='filterDrop' v-model="filterDropItem" @open='openFilter($event)')
        template(#title)
          div.van-ellipsis 筛选
        
  //- 筛选抽屉
  van-popup(v-model="showFilter" position="right" :style="{ width: '80%', height: '100%' }" @close='$refs.filterDrop.toggle(false)')
    .filter-container
      .filter-body
        .filter-body__scroll
          van-collapse(v-model='activeNames')
            van-collapse-item(title='系列' name='1')
              van-tag(v-for="ser in series" :key="ser.value" 
                :type="selectSerie.indexOf(ser.value) != -1  ? 'primary' : 'default'" 
                style="margin:5px;"
                size="large"
                @click="onSelectSerie(ser.value)"
              ) {{ser.text}}
            van-collapse-item(title='品牌' name='2')
              van-tag(v-for="ser in brands" :key="ser.value" 
                :type="selectBrand.indexOf(ser.value) != -1 ? 'primary' : 'default'" 
                style="margin:5px;"
                size="large"
                @click="onSelectBrand(ser.value)"
              ) {{ser.text}}
            van-collapse-item(title='商品分类' name='3')
              van-tag(v-for="ser in types" :key="ser.value" 
                :type="selectType.indexOf(ser.value) != -1 ? 'primary' : 'default'" 
                style="margin:5px;"
                size="large"
                @click="onSelectType(ser.value)"
              ) {{ser.text}}
            van-collapse-item(title='空间分类' name='4')
              van-tag(v-for="ser in fields" :key="ser.value" 
                :type="selectField.indexOf(ser.value) != -1 ? 'primary' : 'default'" 
                style="margin:5px;"
                size="large"
                @click="onSelectField(ser.value)"
              ) {{ser.text}}
            van-collapse-item(title='风格分类' name='5')
              van-tag(v-for="ser in styles" :key="ser.value" 
                :type="selectStyle.indexOf(ser.value) != -1 ? 'primary' : 'default'" 
                style="margin:5px;"
                size="large"
                @click="onSelectStyle(ser.value)"
              ) {{ser.text}}
      div     
        van-goods-action
          van-goods-action-button(color='#ff976a' type='primary' text='重置' @click='filterReset')
          van-goods-action-button(color='#ff6034' type='primary' text='确认' @click='filterSubmit')
   
  van-pull-refresh(
    v-model="refreshing"
    @refresh="onRefresh()"
    )
    div(style="min-height:800px;")
      van-list(
        v-model="loading"
        :finished="finished"
        @load="onLoad(getQuery())"
        finished-text="没有更多了"
        )
        van-grid(:border='false', :column-num='2' :gutter="5" style="margin-top:10px;")
          van-grid-item.dp-grid(
            v-for="(good, index) of goods"
            :key="index"
            :to="good.to")
            van-image(:src="good.image + previewSize" width="40vw" height="40vw" fit="cover")
            span.van-grid-item__text {{good.name}}
</template>

<script>
import { Sticky } from 'vant'
import { previewSize } from '../../lib/helpers/OssImageHelper'
let promise = {}
export default {
  components: {
    [Sticky.name]: Sticky
  },
  data() {
    return {
      scrollTop: 0,
      activeNames: ['1', '2', '3', '4', '5'],
      previewSize,
      refreshing: false,
      loading: false,
      querying: false,
      finished: false,
      searchKey: '',
      value: 0,
      switch1: false,
      switch2: false,
      showFilter: false,
      filterDropItem: 0,
      page:{
        limit: this.$store.state.limit,
        skip: 0
      },
      option: [
        { text: '默认排序', value: 0 },
        { text: '创建时间', value: 1 },
        { text: '更新时间', value: 2 }
      ],
      selectSerie: [-1],
      selectBrand: [-1],
      selectType: [-1],
      selectField: [-1],
      selectStyle: [-1],
      selectSecondType: [-1],    // 二级分类
      typeConfig: null,
      series: [],       // 系列
      brands: [],       // 品牌
      fields: [],       // 空间
      styles: [],       // 风格
      types: [],        // 商品3级分类
      goods: [
        // {
        //   id: 1,
        //   name: '商品名称',
        //   image: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        //   to: {
        //     name: 'detail',
        //     query: {
        //       id: '1'
        //     }
        //   }
        // }
      ]
    }
  },
  computed: {
    hallData() {
      return this.$store.state.hallData
    },
    companySelfType() {
      return this.$store.state.companySelfType
    },
  },
  watch: {
    value() {
      this.onSearch()
    },
    // selectSerie() {
    //   this.onSearch()
    // },
    hallData() {
      this.searchKey = this.$route.query.searchKey
      if (this.searchKey) {
        this.onSearch()
      } else {
        this.onLoad(this.$route.query)
      }
    }
  },
  methods: {
    openFilter (e) {
      e?.stopPropagation()
      this.showFilter = true
    },
    getQuery() {
      let query = {
      }
      if(!this.selectSerie.includes(-1)){
        query.series = this.selectSerie.join(',')
      } else {
        delete query.series
      }
      if(!this.selectBrand.includes(-1)){
        query.brand = this.selectBrand.join(',')
      } else {
        delete query.brand
      }
      if(!this.selectType.includes(-1)){
        query.type = this.selectType.join(',')
      } else {
        delete query.type
      }
      if(!this.selectStyle.includes(-1)){
        query.style = this.selectStyle.join(',')
      } else {
        delete query.style
      }
      if(!this.selectField.includes(-1)){
        query.field = this.selectField.join(',')
      } else {
        delete query.field
      }
      if(!this.selectSecondType.includes(-1)){
        query.sp_type = this.selectSecondType.join(',')
      } else {
        delete query.sp_type
      }
      return {...query,...this.$route.query}
    },
    async filterSubmit () {
      const query= this.getQuery()
      this.finished = false
      this.refreshing = true
      this.loading = true
      window.scroll(0,0);
      await this.onLoad(query)
      this.showFilter = false
    },
    filterReset () {
      this.selectSerie = [-1]
      this.selectBrand = [-1]
      this.selectType = [-1]
      this.selectStyle = [-1]
      this.selectField = [-1]
      this.selectSecondType = [-1]
      this.searchKey = ''
      if (Object.getOwnPropertyNames(this.$route.query).length > 0) {
        this.$router.replace({query: {}})
        this.onSearch()
      }
    },
    onConfirm() {
      this.$refs.item.toggle()
    },
    onSearch() {
      this.refreshing = true
      this.filterSubmit()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.filterSubmit()
    },
    
     // 获取商品数据
    // query(object)条件对象
    async onLoad(objQuery) {
      if (this.querying == true) {
        return
      }
      this.querying = true
      if (this.refreshing) {
        this.goods = []
        this.refreshing = false;
      }
      let order = JSON.stringify([['fur_order', 'DESC']])
      // 排序方式
      switch(this.value) {
        case 1:
          order = JSON.stringify([['created_at', 'DESC']])
          break
        case 2:
          order = JSON.stringify([['updated_at', 'DESC']])
          break
        default: 
          break;  
      }
      const query = objQuery || this.$route.query
      const searchKey = this.searchKey
      // 正常请求
      let params = {
        limit: this.page.limit,
        skip: this.goods.length,
        order,
        keys: JSON.stringify(
          [
            ["fur_id","id"],
            "fur_image",
            "fur_name",
            "fur_code",
            "fur_cost", 
            "fur_order", 
            "fur_states",
            "fur.ftt_id_poi_furniture_top_type",
            "top_type.ftt_name",
            "fur.sp_type_poi_furniture_simple_types", 
            "sp_type.sp_type_name",
            "fur.fur_type_id_poi_furniture_types",
            "type.type_name", 
            "fur.com_id_poi_companys", 
            "com.com_name", 
            "series.companys_self_series_id", 
            "series.companys_self_series_name", 
            "brand.com_brand_id", 
            "brand.com_brand_name",
            "goods_tags"
          ]
        )
      }
      let condition = {
        fur_states: ['up'],
        company: [this.$store.state.routeInfo.comId],
      }
      const { params: outParams } = this.$route
      if (outParams && outParams.type && outParams.type == 'furniture') {
        condition.id = outParams.ids
      }
      if (searchKey) {
        params.searchKey = searchKey
      } 

      // else if(query.searchKey) {
      //   params.searchKey = query.searchKey
      // }

       // 系列
      if (query.series) {
        condition.series = query.series.split(',')
      }

      // 品牌
      if (query.brand) {
        condition.brand = query.brand.split(',')
      }

      // 二级分类
      if (query.sp_type) {
        condition.sp_type = query.sp_type.split(',')
      }
    
      // 三级分类
      if (query.type) {
        condition.type = query.type.split(',')
      }

      // 空间
      if (query.field) { 
        condition.field = query.field.split(',')
      }

      // 风格
      if (query.style) { 
        condition.style = query.style.split(',')
      }

       // 风格
      if (query.mat) { 
        condition.mat = query.mat.split(',')
      }

      // 项目
      if (query.project) { 
        condition.project = query.project.split(',')
      }

      params.condition = JSON.stringify(condition)
      this.loading = true
      try {
        const res = await this.$api.GetGoodsList(params)
        let newGoods = res.data.items.map((item) => {
          return {
            id: item.id,
            name: item.fur_name,
            image: item.fur_image,
            to: {
              name: 'detail',
              query: {
                id: item.id
              }
            }
          }
        })
        this.goods = this.goods.concat(newGoods)
        this.loading = false
        // 加载到尽头了
        if (res.data.items.length < this.page.limit) {
          this.finished = true
        }
        this.querying = false
        this.refreshing = false
      } catch (error) {
        this.$toast('加载数据失败')
        this.loading = false
        this.finished = true
        this.querying = false
        this.refreshing = false
      }
    },

    async getSeries() {
      if (!('series' in promise)) {
        let comId = this.$store.state.routeInfo.comId
        promise['series'] = this.$api.GetSeries(comId)
      }
      let res = await promise['series']
      this.series.push({text:'全部', value: -1})
      res.data.items.map(p=> {
        this.series.push({
          text: p.companys_self_series_name,
          value: p.id
        })
      })
    },

    // 获取品牌
    async getBrands() {
      if (!('brands' in promise)) {
        let params = {
          where: {
            com_id_poi_companys: this.$store.state.routeInfo.comId,
            brands_state: 'on',
          },
          order: '-order',
          keys: 'id, com_brand_name'
        }
        promise['brands'] = this.$api.GetBrands(params)
      }
      let res = await promise['brands']
      this.brands.push({text:'全部', value: -1})
      res.data.items.map(p=> {
        this.brands.push({
          text: p.com_brand_name,
          value: p.id
        })
      })
    },

     // 获取商品3级分类
    async getTypes() {
      if (!('types' in promise)) {
        let params = {
          where: {
          },
          order: '-update_time',
          keys: 'id, type_name'
        }
        if (this.typeConfig) {
          params.where.id = {
            $in: this.typeConfig.companys_self_type_typeid.split(',')
          }
        }
        promise['types'] = this.$api.GetTypes(params)
      }
      let res = await promise['types'] 
      this.types.push({text:'全部', value: -1})
      res.data.items.map(p=> {
        this.types.push({
          text: p.type_name,
          value: p.id
        })
      })
    },

    // 获取风格分类
    async getStyles() {
      if (!('styles' in promise)) {
        let params = {
          where: {
          },
          order: '-style_order',
          keys: 'id, style_name'
        }
        if (this.typeConfig) {
          params.where.id = {
            $in: this.typeConfig.companys_self_type_styleid.split(',')
          }
        }
        promise['styles'] = this.$api.GetStyles(params)
      }
      let res = await promise['styles'] 
      this.styles.push({text:'全部', value: -1})
      res.data.items.map(p=> {
        this.styles.push({
          text: p.style_name,
          value: p.id
        })
      })
    },

    // 获取公司的分类启用配置
    async GetCompanysSelfType() {
      if (!('typeConfig' in promise)) {
        let params = {
          where: {
            com_id_poi_companys: this.$store.state.routeInfo.comId
          },
        }
        promise['typeConfig'] = this.$api.GetCompanysSelfType(params)
      }
      let res = await promise['typeConfig'] 
      this.typeConfig =  res.data.items.length > 0 ? res.data.items[0] : null
    },

    // 获取空间分类
    async getFields() {
      if (!('fields' in promise)) {
        let params = {
          where: {
          },
          order: '-field_order',
          keys: 'id, field_name'
        }
        if (this.typeConfig) {
          params.where.id = {
            $in: this.typeConfig.companys_self_type_fieldid.split(',')
          }
        }
        promise['fields'] = this.$api.GetFields(params)
      }
      let res = await promise['fields'] 
      this.fields.push({text:'全部', value: -1})
      res.data.items.map(p=> {
        this.fields.push({
          text: p.field_name,
          value: p.id
        })
      })
    },
    
    onSelectSerie(value) {
      this.selectSerie = this.onMultipleSelect(this.selectSerie, value)
    },
    onSelectBrand(value) {
      this.selectBrand = this.onMultipleSelect(this.selectBrand, value)
    },
    onSelectType(value) {
      this.selectType = this.onMultipleSelect(this.selectType, value)
    },
    onSelectField(value) {
      this.selectField = this.onMultipleSelect(this.selectField, value)
    },
    onSelectStyle(value) {
      this.selectStyle = this.onMultipleSelect(this.selectStyle, value)
    },
    onMultipleSelect(obj, value) {
      if (value <= 0) {
        obj = [-1]
      } else {
        if(obj.includes(value)) {
          obj = obj.filter(item => item != value)
        } else {
          obj.push(value)
          obj = obj.filter(item => item > 0)
        }
        if (obj.length == 0) {
          obj = [-1]
        }
      }
      return obj
    }
  },
  async mounted() {
    
  },
  beforeRouteLeave(to, from, next){
    if (to.name == 'detail') {
      this.scrollTop = document.documentElement.scrollTop
    } else {
      this.scrollTop = 0
    }
    next()
  },
  activated() {
    const query = this.$route.query || {}
    this.selectSerie = query.series ? query.series.split(',') : [-1]
    this.selectBrand = query.brand ? query.brand.split(',') : [-1]
    this.selectType = query.type ? query.type.split(',') : [-1]
    this.selectStyle = query.style ? query.style.split(',') : [-1]
    this.selectField = query.field ? query.field.split(',') : [-1]
    this.selectSecondType = query.sp_type ? query.sp_type.split(',') : [-1]
    this.searchKey = query.searchKey

    this.typeConfig = this.companySelfType || null
    // await this.GetCompanysSelfType()
    // this.onRefresh()
    this.getSeries()
    this.getBrands()
    this.getStyles()
    this.getTypes()
    this.getFields()

    this.$nextTick(()=> {
      this.$emit('on-nav', {
        leftText: '返回',
        leftArrow: true,
        onClickLeft: () => {
          this.$router.back(-1)
        }
      })

      window.scroll(0,this.scrollTop);
      if ((!this.scrollTop || this.scrollTop <= 0)) {
        this.goods = []
        this.onLoad(this.$route.query)
      }
    })
  }
}
</script>

<style lang="less" scoped>
.filter {
  display: flex;
  align-items: center;
}

.filter-container {
  position: relative;
  height: 100%;
}

.filter-body {
  overflow-y: auto;
  height: 100%;
}

.filter-body__scroll {
  padding-bottom: 50px;
}

.filter-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}
</style>